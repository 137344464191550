import { useSpring } from '@react-spring/web'
import { easeOutQuad } from '/machinery/easings'
import { useScrollProgression, triggers } from '@kaliber/scroll-progression'
import { lerp } from '@kaliber/math'

export function useParralax(index) {
  const parallaxValue = (
    index === 0 ? 16 :
    index === 1 ? 24 :
    8
  )
  const divRef = useScrollProgression({
    start: { element: triggers.top(), scrollParent: triggers.bottom() },
    end: { element: triggers.bottom(), scrollParent: triggers.top() },
    onChange(progression) {
      springApi.start({ translateY: `${lerp({ start: parallaxValue, end: -parallaxValue, input: easeOutQuad(progression) })}%` })
    }
  })
  const [{ translateY }, springApi] = useSpring(() => ({ translateY: `-${parallaxValue}%`, config: { tension: 80, friction: 55 } }))
  return { translateY, divRef }
}
