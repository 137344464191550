import { animated } from '@react-spring/web'
import { ImageCover } from '/features/buildingBlocks/Image'
import { useParralax } from '/machinery/useParralax'

import styles from './ImageGrid.css'

export function ImageGrid({ images, layoutClassName = undefined }) {

  return images && (
    <div className={cx(styles.component, layoutClassName)}>
      <div className={styles.images}>
        {images.map((image, index) => <Image key={index} {...{ image, index }} /> )}
      </div>
    </div>
  )
}

function Image({ image, index }) {
  const aspectRatio = (
    index === 0 ? 1 / 1 :
    index === 1 ? 16 / 9 :
    9 / 16
  )

  const animation = useParralax(index)
  const { translateY, divRef } = animation

  return (
    <animated.div ref={divRef} className={cx(styles.componentImage,
      index === 0 && styles.image1,
      index === 1 && styles.image2,
      index === 2 && styles.image3
    )} style={{ translateY }}>
      <ImageCover {... { image, aspectRatio }} imgProps={{ loading: 'lazy' }} />
    </animated.div>
  )
}
