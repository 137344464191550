import {
  Image as ImageBase,
  ImageCropped as ImageCroppedBase,
  ImageCover as ImageCoverBase
} from '@kaliber/sanity-image'
import { useClientConfig } from '/machinery/ClientConfig'

export function Image({ image, sizes = undefined, imgProps = {}, layoutClassName = undefined }) {
  const { sanity } = useClientConfig()
  return (
    <ImageBase
      sanityConfig={sanity.clients.readOnly}
      imgProps={{ ...imgProps, alt: image?.alt }}
      {...{ image, sizes, layoutClassName }}
    />
  )
}

export function ImageCropped({ image, aspectRatio, sizes = undefined, imgProps = {}, layoutClassName = undefined }) {
  const { sanity } = useClientConfig()
  return (
    <ImageCroppedBase
      sanityConfig={sanity.clients.readOnly}
      imgProps={{ ...imgProps, alt: image?.alt }}
      {...{ image, aspectRatio, sizes, layoutClassName }}
    />
  )
}

export function ImageCover({ image, aspectRatio, sizes = undefined, imgProps = {}, layoutClassName = undefined }) {
  const { sanity } = useClientConfig()
  return (
    <ImageCoverBase
      sanityConfig={sanity.clients.readOnly}
      imgProps={{ ...imgProps, alt: image?.alt, className: layoutClassName }}
      {...{ image, aspectRatio, sizes, layoutClassName }}
    />
  )
}
